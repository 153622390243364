import {
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  TransferState,
  ViewChild,
  afterNextRender,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { CartService } from '@/app/shared/services/cart.service';
import { CommonService } from '@/app/shared/services/common.service';

import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
import {
  AddUserOrderModel,
  Cart,
  CartItem,
  PaymentMethod,
  TransportMethod,
  UserInfo,
} from '@/app/shared/interface/cart.interface';
// import $ from 'jquery';
import { OrderService } from '@/app/shared/services/order.service';
import { Observable, Subscription, combineLatest, firstValueFrom } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AddressService } from '@/app/shared/services/address.service';
import {
  DeliveryAddress,
  DeliveryAddressModel,
  StatusAddress,
  User,
  UserTier,
} from '@/app/shared/interface/user.interface';
import { AccountService } from '@/app/shared/services/account.service';
import { isPlatformBrowser } from '@angular/common';
import { VoucherService } from '@/app/shared/services/voucher.service';
import { Voucher } from '@/app/shared/interface/voucher.interface';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { EventEmitter, Input, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService, TOKEN } from '@/app/shared/services/auth.service';
import { AddressModalComponent } from '@/app/shared/widgets/address-modal/address-modal.component';
import { jwtDecode } from 'jwt-decode';
import { ExchangeGiftModalComponent } from '@/app/shared/widgets/modal/exchange-gift-modal/exchange-gift-modal.component';
import { Product, ProductModel, WholesalePriceInfo } from '@/app/shared/interface/product.interface';
import { GiftService } from '@/app/shared/services/gift.service';
import { OrderModel } from '@/app/shared/interface/order.interface';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent {
  @ViewChild('confirmDeleteModal', { static: false }) confirmDeleteModal: any;
  @ViewChild('confirmCheckoutModal', { static: false })
  confirmCheckoutModal: any;
  @ViewChild('voucherModal', { static: false }) voucherModal: any;
  @ViewChild('addressModal') AddressModal: AddressModalComponent;
  @ViewChild('exchangeGiftModal') exchangeGiftModal: ExchangeGiftModalComponent;

  @Output() productLabel: EventEmitter<any> = new EventEmitter<any>();

  cart: Cart = new Cart();
  cartTotal: number = 0;
  selected: number = 0;
  selectedItems: CartItem[] = [];
  selectedItem: CartItem;
  totalQuantity: number = 0;

  isDeletingSelectedItems: boolean = false;
  usingVoucher: boolean = true;
  vouchers: Voucher[] = [];

  addresses: DeliveryAddress[] = [];
  defaultAddress: DeliveryAddress;
  selectedAddressId: string = '';
  isAccountActivated: boolean = false;
  selectedUserInfoAddress: UserInfo = new UserInfo();

  user: User;
  userTier: number = 1;

  bankInfo: any = {
    name: 'Ngân hàng Vietinbank - Chi nhánh Hoàn Kiếm',
    owner: 'CONG TY CO PHAN THUONG MAI DUOC VUONG',
    account: '112002910015',
  };

  public breadcrumb: Breadcrumb = {
    title: 'Giỏ Hàng',
    items: [{ label: 'Giỏ Hàng', active: true }],
  };

  showMoreArress: boolean = false;
  private routerEvent: Subscription;

  isBrowser: boolean = false;

  transportMethods: TransportMethod[];

  selectedTransportMethod: TransportMethod;
  selectedPaymentMethod: PaymentMethod;

  addressEdit: any;

  giftInfo: any;
  giftList: Product[] = [];
  cartItemsMap: Map<string, any> = new Map();

  skeletonLoader: boolean = false;
  public skeletonItems = Array.from({ length: 3 }, (_, index) => index);

  constructor(
    private cartService: CartService,
    private commonService: CommonService,
    private toastService: ToastrService,
    private orderService: OrderService,
    private addressService: AddressService,
    private router: Router,
    private accountService: AccountService,
    private voucherService: VoucherService,
    private giftService: GiftService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private transferState: TransferState,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
  ) {
    this.isAccountActivated = this.authService.isAccountActivated();
    this.userTier = Number(this.authService.getUserTier());
  }

  ngOnInit() {
    this.getCart();
    this.getGiftList();

    this.getVouchers();

    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }

    this.getAddresses();

    this.getTransportMethods();
   
    this.accountService.getUser().subscribe({
      next: (res: User) => {
        if (res) {
          this.getGiftInfo();
          this.giftService.getTotalGiftPoint().subscribe({
            next: (giftPoint: any) => {
              res.giftPoint = giftPoint.totalPoint;
              this.user = res;
            },
            error: (error: any) => {
              console.log(error);
            },
          });
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  getCart() {
    this.skeletonLoader = true;
    this.commonService.getCart.subscribe({
      next: (res: any) => {
        if (res) {
          this.cart = res;
          this.totalQuantity = this.cart.items.reduce((current, next) => {
            return current + next.quantity;
          }, 0);

          this.cartItemsMap = this.createCartItemsMap(this.cart.items);
          this.skeletonLoader = false;
        }
      },
    });
    this.routerEvent = this.router.events.subscribe({
      next: (val: any) => {
        if (val instanceof NavigationEnd) {
          if (this.router.url.includes('/cart')) {
            if (isPlatformBrowser(this.platformId)) {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          }
        }
      },
    });
  }
  
  getGiftInfo() {
    this.giftService.getGiftInfo.subscribe({
      next: (res: any) => {
        if (res) {
          this.giftInfo = res;
        }
      }
    });
  }

  getGiftList() {
    let body = {
      limit: 100,
      offset: 0,
    }
    this.giftService.getGiftList(JSON.stringify(body)).subscribe({
      next: (res: ProductModel) => {
        if (res && res.products.length > 0) {
          this.giftList = res.products;
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  getCartItem(product: any) {
    let cartItem = {
      imgUrl: product.imageUrls[0],
      name: product.name,
      price: product.basePrice,
      basePrice: product.basePrice,
      productID: product.productID,
      quantity: 0,
      labels: product.labels,
      SKU: product.SKU,
      slug: product.slug,
    };

    const existingCartItem = this.cartItemsMap.get(product.productID);
    if (existingCartItem) {
      cartItem = existingCartItem;
    }

    return cartItem;
  }

  createCartItemsMap(cartItems: any[]): Map<string, any> {
    const cartItemsMap = new Map<string, any>();
    for (let item of cartItems) {
      this.getNextWholesalePrice(item);
      cartItemsMap.set(item.productID, item);
    }
    return cartItemsMap;
  }

  getAddresses() {
    if (this.isBrowser) {
      // const token = this.cookieService.get('token');
      const token = this.transferState.get(TOKEN, '');
      if (!token) {
        return;
      }
      // if (!localStorage.getItem("token")?.length) {
      //   return;
      // }
    }

    this.addressService.getUserAddress().subscribe({
      next: (res: DeliveryAddressModel) => {
        if (res && res.deliveryAddress.length > 0) {
          this.addresses = res.deliveryAddress;
          this.defaultAddress = this.addresses.find(
            (a) => a.status == StatusAddress.Default
          )!;
          this.addresses.sort((a, b) => {
            if (a.status > b.status) {
              return 1;
            }
            if (a.status < b.status) {
              return -1;
            }
            return 0;
          });
          this.selectAddress(0);
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  getVouchers() {
    this.voucherService.getAvailableVouchers().subscribe({
      // this.route.data.subscribe({
      // this.voucherService.getAvailableVouchers().subscribe({
      next: (res: any) => {
        this.vouchers = res;
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  showMore() {
    this.showMoreArress = !this.showMoreArress;
  }

  selectAddress(index: number) {
    if (!this.cart.userInfo) {
      this.cart.userInfo = new UserInfo();
    }
    let selectedAddress = this.addresses[index];
    this.selectedAddressId = selectedAddress.id;

    this.selectedUserInfoAddress.id = this.selectedAddressId;
    this.selectedUserInfoAddress.address = `${selectedAddress.address}, ${selectedAddress.province.name}, ${selectedAddress.province.districts[0].name}, ${selectedAddress.province.districts[0].wards[0].name}`;
    this.selectedUserInfoAddress.phone = selectedAddress.receiverPhone;
    this.selectedUserInfoAddress.userName = selectedAddress.receiverName;

    this.cart.userInfo = this.selectedUserInfoAddress;
  }

  delete(product: any) {
    let body = {
      productId: product.productID,
      quantity: 0,
    };
    this.cartService.updateCart(JSON.stringify(body)).subscribe({
      next: (res: any) => {
        product.quantity = body.quantity;
        this.commonService.setCart(res);
        this.selectedItems = this.selectedItems.filter(x => x.productID != product.productID);
        this.toastService.success('Đã cập nhật giỏ hàng!', '', {
          positionClass: 'toast-top-right',
        });
      },
      error: (error) => {
        product.quantity = 0;
        console.log(error);
        this.toastService.warning('Lỗi cập nhật giỏ hàng!', '', {
          positionClass: 'toast-top-right',
        });
      },
    });
  }

  checkAll() {
    $('.chk').prop('checked', $('.chk-all').prop('checked'));
    this.selected = $('.chk:checked').length;
    if ($('.chk').prop('checked')) {
      this.selectedItems = this.cart.items;
    } else {
      this.selectedItems = [];
    }
  }

  selectCheckbox(cartItem: CartItem) {
    $('.chk-all').prop('checked', $('.chk').length == $('.chk:checked').length);
    this.selected = $('.chk:checked').length;
    if (
      !this.selectedItems.some((item) => item.productID == cartItem.productID)
    ) {
      this.selectedItems.push(cartItem);
    } else {
      this.selectedItems = this.selectedItems.filter(
        (item) => item.productID != cartItem.productID
      );
    }
  }

  deleteSelectedItems() {
    if (this.selectedItems.length == 0) {
      this.toastService.error('Xin vui lòng chọn sản phẩm!', '', {
        positionClass: 'toast-top-right',
      });
      return;
    }
    this.isDeletingSelectedItems = true;
    this.confirmDeleteModal.openModal();
  }

  confirmDelete(value?: boolean) {
    if (value) {
      if (this.isDeletingSelectedItems) {
        this.updateMany(this.selectedItems);
      } else {
        this.delete(this.selectedItem);
      }
    }
    // else {
    //   if (this.selectedItems.length > 0 && !$('#' + this.selectedItem.productID).prop('checked')) {
    //     this.selectedItems = this.selectedItems.filter(item => item.productID != this.selectedItem.productID);
    //   }
    // }
    this.isDeletingSelectedItems = false;
  }

  openModal(cartItem: CartItem) {
    this.selectedItem = cartItem;
    if (
      !this.selectedItems.some((item) => item.productID == cartItem.productID)
    ) {
      this.selectedItems.push(cartItem);
    }
    this.confirmDeleteModal.openModal();
  }

  checkout() {
    // Dont know why i cant use jquery here
    // $('#agree').prop('checked')
    let checkbox = document.getElementById('agree') as HTMLInputElement;
    if (checkbox && !checkbox.checked) {
      this.toastService.warning('Bạn chưa đồng ý với điều khoản sử dụng!', '', {
        positionClass: 'toast-top-right',
      });
      return;
    }

    let note = document.getElementById('note') as HTMLTextAreaElement;
    this.cart.note = note.value;

    if (!this.selectedUserInfoAddress.address) {
      this.toastService.warning('Chưa có thông tin địa chỉ giao hàng vui lòng thêm thông tin!', '', {
        positionClass: 'toast-top-right',
      });
      return;
    }

    this.cart.userInfo = this.selectedUserInfoAddress;

    this.confirmCheckoutModal.openModal();
  }

  confirmCheckout(value?: boolean) {
    if (value) {
      const element = document.getElementById('bt');
      element!.classList.remove('btn-checkout');
      element!.classList.add('btn-disable');

      this.cart.paymentMethod = 'bank';
      this.cart.transportMethod = this.selectedTransportMethod.codeTransport;

      if (this.selectedTransportMethod?.paymentTerm.length > 0) {
        this.cart.paymentMethod = this.selectedPaymentMethod.code;
      }

      const token = this.transferState.get(TOKEN, '');
      const jwt = jwtDecode(token) as any;
      this.cart.userInfo.status = Number(UserTier[jwt.Status]);

      const cartBody: AddUserOrderModel = {
        cart: this.cart,
        transport: this.selectedTransportMethod,
      };
      this.orderService.createUpdateOrderV1(cartBody).subscribe({
        next: (res: any) => {
          if (res.statusCode === 201) {
            this.toastService.warning(res.message, '', {
              positionClass: 'toast-top-right',
            });
            this.commonService.setCart(new Cart());
          }
          else {
            this.toastService.success('Tạo đơn hàng thành công!', '', {
              positionClass: 'toast-top-right',
            });
            this.commonService.setCart(new Cart());
            this.router.navigate(['/thank-you'], { state: res });
          }
          this.giftService.getTotalGiftPoint().subscribe({
            next: (giftPoint: any) => {
              res.giftPoint = giftPoint.totalPoint;
              this.accountService.setUserData(this.user);
            },
            error: (error: any) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error);
          element!.classList.remove('btn-disable');
          element!.classList.add('btn-checkout');
          this.toastService.warning(error.error.message, '', {
            positionClass: 'toast-top-right',
          });
        },
      });
    }
  }

  updateMany(list: CartItem[]) {
    let items: any[] = [];
    for (const cartItem of list) {
      const item = {
        productId: cartItem.productID,
        quantity: 0,
      };
      items.push(item);
    }
    const body = {
      items: items,
    };
    this.cartService.updateCartMany(JSON.stringify(body)).subscribe({
      next: (res: any) => {
        this.cart = res;
        this.commonService.setCart(this.cart);
        this.toastService.success('Đã cập nhật giỏ hàng', '', {
          positionClass: 'toast-top-right',
        });
      },
      error: (error) => {
        console.log(error);
        this.toastService.warning(error.error.message, '', {
          positionClass: 'toast-top-right',
        });
      },
    });
  }

  applyVoucher(voucher: string) {
    if (voucher) {
      const body = {
        voucherCode: voucher,
      };
      this.cartService.applyVoucher(JSON.stringify(body)).subscribe({
        next: (res: Cart) => {
          if (res) {
            this.cart = res;
          }
        },
        error: (error: any) => {
          // this.toastService.warning('Mã giảm giá hết hạn hoặc đơn hàng chưa đủ điều kiện !! Vui lòng kiểm tra lại', "", { positionClass: "toast-top-right" });
          this.toastService.warning(error.error.detail, '', {
            positionClass: 'toast-top-right',
          });
        },
      });
    } else {
      this.toastService.warning('Vui lòng nhập mã giảm giá!', '', {
        positionClass: 'toast-top-right',
      });
    }
  }

  deleteVoucher() {
    this.cartService.removeVoucher().subscribe({
      next: (res: Cart) => {
        if (res) {
          // this.cart = res;
          this.toastService.success('Đã xóa mã giảm giá', '', {
            positionClass: 'toast-top-right',
          });
          this.cartService.getCarts().subscribe({
            next: (res: any) => {
              this.cart = res;
              this.commonService.setCart(this.cart);
            },
          });
        }
      },
      error: (error: any) => {
        this.toastService.warning(
          'Mã giảm giá hết hạn hoặc sai !! Vui lòng nhập lại',
          '',
          { positionClass: 'toast-top-right' }
        );
      },
    });
  }

  openVoucherModal() {
    this.voucherModal.openModal();
  }

  selectVoucher(event: any) {
    // this.applyVoucher(event.target.value);
  }

  changeTransportMethod(transportMethod: TransportMethod) {
    // call api to get payment method
    this.selectedTransportMethod = transportMethod;
    this.changePaymentMethod(transportMethod?.paymentTerm[0]);
  }

  queryStringBuilder(slug: string) {
    this.productLabel.emit(slug);
    // set label query string
    this.router.navigate(['/product'], {
      queryParams: {
        label: slug,
      },
      queryParamsHandling: '', // optional
    });
  }

  changePaymentMethod(paymentMethod: PaymentMethod) {
    this.selectedPaymentMethod = paymentMethod;
  }

  ngOnDestroy() {
    this.routerEvent?.unsubscribe();
  }

  getTransportMethods() {
    this.cartService.getTranferMethods();
    this.cartService.getUserTransportData.subscribe({
      next: (res: TransportMethod[]) => {
        this.transportMethods = res;

        if (this.transportMethods.length > 0) {
          this.selectedTransportMethod = this.transportMethods[0];
        }

        if (this.selectedTransportMethod?.paymentTerm.length > 0) {
          this.selectedPaymentMethod = this.selectedTransportMethod.paymentTerm[0];
        }
      }
    });
  }

  resetAddress() {
    this.addressEdit = null;
  }

  addAddressAfterCreate(event: DeliveryAddress) {
    this.getAddresses();
  }

  onEditAddress(address: any) {
    this.addressEdit = address;
    this.AddressModal.openModal();
  }

  addAddress() {
    this.addressEdit = null;
    this.AddressModal.openModal();
  }

  getNextWholesalePrice(cartItem: CartItem) {
    if (cartItem.wholesalePrices?.length) {
      for (let i = 0; i < cartItem.wholesalePrices.length; i++) {
        if (cartItem.quantity < cartItem.wholesalePrices[i].minQuantity) {
          cartItem.wholesalePriceInfo = cartItem.wholesalePrices[i];
          break;
        }
      }
    }
  }
}
